import { ActionTypesEnum } from 'src/context/ActionTypesEnum';
import { AppActionType, AppStateType } from 'src/types';

export const appReducer: React.Reducer<AppStateType, AppActionType> = (
  state,
  action
) => {
  switch (action.type) {
    case ActionTypesEnum.APP_SHOW_NOTIFICATION: {
      return { ...state, notification: action.payload };
    }
    case ActionTypesEnum.APP_HIDE_NOTIFICATION: {
      return { ...state, notification: '' };
    }
    default:
      return state;
  }
};
