import React, { useCallback, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';

import { MaybeType } from 'src/types';
import CanvasEffectsContainer from 'src/components/Canvas/CanvasEffectContainer';
import pic from 'src/assets/logoVZ-png.png';
import './welcome.scss';

const Welcome: React.FC = () => {
  const [toggle, setToggle] = useState<boolean>(true);

  const refDiv = useRef<HTMLDivElement>(null);

  const cbV3 = useCallback(() => {
    let elem: MaybeType<HTMLElement> = refDiv?.current;
    CanvasEffectsContainer.canvasEffectV3(elem!);
  }, []);

  return (
    <Transition
      in={toggle}
      timeout={{ enter: 1500, exit: 3300 }}
      mountOnEnter
      unmountOnExit
      onExit={cbV3}
      onExited={CanvasEffectsContainer.clearCanvasContainer}
    >
      <div ref={refDiv} className="welcome" onClick={() => setToggle(!toggle)}>
        <div className="welcome__title">{/* <img src={pic} alt="" /> */}</div>
        <div className="welcome__body">
          {/* <p>Добро пожаловать на проект Dev Way</p> */}
          <p>Hello! My name is Vsevolod Zorin</p>
          {/* <p>
            В данный момент сайт находится в режиме разработки, по этому часть
            функционала может быть недоступна.
          </p> */}
          <p>
            At the moment the site is in development mode, so part of
            functionality may not be available.
          </p>

          <a
            href="https://firebasestorage.googleapis.com/v0/b/vsevolod-dev.appspot.com/o/React.js%20Node.js%20Vsevolod%20Zorin.docx?alt=media&token=7339efa0-4e7c-494c-9a4e-a107885efbcb"
            target="_blank"
            style={{
              color: 'black',
              textDecoration: 'underline',
            }}
          >
            <span>download CV</span>
          </a>

          <br />
        </div>
        <div className="welcome__line"></div>
      </div>
    </Transition>
  );
};

export default Welcome;
