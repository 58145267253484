import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppProvider from 'src/context/App/AppProvider';
import renderRoutes from 'src/routes';
import Layout from 'src/components/Layout';
import Pace from 'src/components/Pace';

import './app.scss';

function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        {/* <Pace color="red" /> */}
        <Layout>{renderRoutes()}</Layout>
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
