import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Welcome from './components/Welcome';

const renderRoutes = (isAuthenticated: boolean = false) => {
  const Blog = lazy(() => import('src/pages/Blog'));
  const About = lazy(() => import('src/pages/About'));
  const Contacts = lazy(() => import('src/pages/Contacts'));
  const Projects = lazy(() => import('src/pages/Projects'));
  let routes = (
    <Suspense fallback={<Fragment />}>
      <Switch>
        {/* <Route exact path="/" component={About} />
        <Route path="/projects" component={Projects} />
        <Route path="/blog" component={Blog} />
        <Route path="/about" component={About} />
        <Route path="/contacts" component={Contacts} /> */}
        <Route path="/welcome" component={Welcome} />
        <Redirect to="/welcome" />
      </Switch>
    </Suspense>
  );
  return routes;
};

export default renderRoutes;
