import html2canvas from 'html2canvas';

export default class CanvasEffectsContainer {
  /**
   * --canvas-effects-container-top
   * --canvas-effects-container-left
   * --canvas-effects-container-width
   * --canvas-effects-container-height
   */
  static containerAsElement = (htmlEl: HTMLElement) => {
    const htmlElParams = htmlEl.getBoundingClientRect();
    document.documentElement.style.setProperty(
      '--canvas-effects-container-top',
      `${htmlElParams.top}px`
    );
    document.documentElement.style.setProperty(
      '--canvas-effects-container-left',
      `${htmlElParams.left}px`
    );

    document.documentElement.style.setProperty(
      '--canvas-effects-container-width',
      `${htmlElParams.width}px`
    );
    document.documentElement.style.setProperty(
      '--canvas-effects-container-height',
      `${htmlElParams.height}px`
    );
  };

  static clearCanvasContainer = () => {
    var element = document.getElementById('canvas-effects-container');
    console.log(element);
    while (element?.firstChild) {
      element.removeChild(element.firstChild);
    }
  };

  /**  canvas-effects-container z-index : 0*/
  static zIndex0 = () => {
    document.documentElement.style.setProperty(
      '--canvas-effects-container-z-index',
      `0`
    );
  };

  /**  canvas-effects-container z-index : 10*/
  static zIndex10 = () => {
    document.documentElement.style.setProperty(
      '--canvas-effects-container-z-index',
      `10`
    );
  };

  /** htmlEl.style.opacity = '0' */
  static elementOpacity0 = (htmlEl: HTMLElement) => {
    htmlEl.style.opacity = '0';
  };

  /** htmlEl.style.opacity = '1' */
  static elementOpacity1 = (htmlEl: HTMLElement) => {
    htmlEl.style.opacity = '1';
  };

  static canvasEffectV1 = (htmlEl: HTMLElement) => {
    html2canvas(htmlEl).then((canvas: HTMLCanvasElement) => {
      let width = canvas.width;
      let height = canvas.height;
      document.documentElement.style.setProperty(
        '--canvas-effects-container-width',
        `${width}px`
      );
      document.documentElement.style.setProperty(
        '--canvas-effects-container-height',
        `${height}px`
      );
      document.documentElement.style.setProperty(
        '--your-variable',
        '#YOURCOLOR'
      );
      const ctx = canvas.getContext('2d');
      let idata: ImageData = ctx?.getImageData(
        0,
        0,
        width,
        height
      ) as ImageData;
      let datums: ImageData[] = [];

      if (idata) {
        for (let i = 0; i < 32; i++) {
          datums.push(ctx?.createImageData(width, height) as ImageData);
        }

        for (let f = 0; f < width; f++) {
          for (let k = 0; k < height; k++) {
            let n = 4 * (k * width + f);
            let m = Math.floor(32 * Math.random());
            for (let p = 0; p < 4; p++) {
              datums[m].data[n + p] = idata.data[n + p];
            }
            for (let l = 0; l < 2; l++) {}
          }
        }
      }

      datums.forEach((imageData, i) => {
        let cloned = canvas.cloneNode() as HTMLCanvasElement;

        cloned.style.transition = 'all 1.5s ease-out'; // transform opacity

        cloned.getContext('2d')?.putImageData(imageData as ImageData, 0, 0);
        document
          .getElementById('canvas-effects-container')
          ?.appendChild(cloned);

        setTimeout(() => {
          let angle = ((Math.random() - 0.5) * 2 * Math.PI) as number;

          cloned.style.transform =
            'translate(' +
            60 * Math.cos(angle) +
            'px, ' +
            60 * Math.sin(angle) +
            'px)';

          cloned.style.opacity = '0';
        }, 1000);
      });
    });
  };

  static canvasEffectV2 = (htmlEl: HTMLElement) => {
    html2canvas(htmlEl).then((canvas: HTMLCanvasElement) => {
      document.documentElement.style.setProperty(
        '--canvas-effects-container-width',
        `${canvas.width}px`
      );
      document.documentElement.style.setProperty(
        '--canvas-effects-container-height',
        `${canvas.height}px`
      );
      let width = canvas.width;
      let height = canvas.height;
      // document.documentElement.style.setProperty('--your-variable', '#YOURCOLOR');
      const ctx = canvas.getContext('2d');
      let idata: ImageData = ctx?.getImageData(
        0,
        0,
        width,
        height
      ) as ImageData;
      let datums: ImageData[] = [];

      if (idata) {
        for (let i = 0; i < 32; i++) {
          datums.push(ctx?.createImageData(width, height) as ImageData);
        }

        for (let f = 0; f < width; f++) {
          for (let k = 0; k < height; k++) {
            let n = 4 * (k * width + f);
            let m = Math.floor(32 * Math.random());
            for (let p = 0; p < 4; p++) {
              datums[m].data[n + p] = idata.data[n + p];
            }
            for (let l = 0; l < 2; l++) {}
          }
        }
      }

      datums.forEach((imageData, i) => {
        let cloned = canvas.cloneNode() as HTMLCanvasElement;

        cloned.style.transition = 'all 1.5s ease-out'; // transform opacity

        cloned.getContext('2d')?.putImageData(imageData as ImageData, 0, 0);
        document
          .getElementById('canvas-effects-container')
          ?.appendChild(cloned);

        setTimeout(() => {
          let angle = ((Math.random() - 0.5) * 2 * Math.PI) as number;

          cloned.style.transform =
            'translate(' +
            60 * Math.cos(angle) +
            'px, ' +
            60 * Math.sin(angle) +
            'px)';

          cloned.style.opacity = '0';
        }, 1000);
      });
    });
  };

  static canvasEffectV3 = (htmlEl: HTMLElement) => {
    html2canvas(htmlEl).then((canvas: HTMLCanvasElement) => {
      CanvasEffectsContainer.containerAsElement(htmlEl);

      htmlEl.style.opacity = '0';

      let width = canvas.width;
      let height = canvas.height;
      // console.group('width height');
      // console.log('canvas', canvas);
      // console.log('width', width);
      // console.log('height', height);
      // console.groupEnd();

      const ctx = canvas.getContext('2d');
      let idata: ImageData = ctx?.getImageData(
        0,
        0,
        width,
        height
      ) as ImageData;
      let datums: ImageData[] = [];

      if (idata) {
        for (let i = 0; i < 32; i++) {
          datums.push(ctx?.createImageData(width, height) as ImageData);
        }

        for (let f = 0; f < width; f++) {
          for (let k = 0; k < height; k++) {
            let n = 4 * (k * width + f);
            let m = Math.floor(32 * Math.random());
            // let m = Math.floor((32 * (Math.random() + (2 * f) / width)) / 3);
            for (let p = 0; p < 4; p++) {
              datums[m].data[n + p] = idata.data[n + p];
            }
          }
        }
      }

      datums.forEach((imageData, i) => {
        let cloned = canvas.cloneNode() as HTMLCanvasElement;

        // cloned.style.transition = 'all 1.5s ease-out'; // transform opacity
        cloned.style.transition = 'all 2.9s ease-out ' + i / 32 + 's';
        // cloned.style.transition = 'all 3s ease-out ';

        cloned.getContext('2d')?.putImageData(imageData as ImageData, 0, 0);
        document
          .getElementById('canvas-effects-container')
          ?.appendChild(cloned);
        setTimeout(() => {
          let angle = ((Math.random() - 0.5) * 2 * Math.PI) as number;
          cloned.style.transform =
            ' translate(' +
            60 * Math.cos(angle) +
            'px, ' +
            60 * Math.sin(angle) +
            'px) ';

          cloned.style.opacity = '0';
        }, 4);
      });
    });
  };

  static canvasEffectV4 = (htmlEl: HTMLElement) => {
    html2canvas(htmlEl).then((canvas: HTMLCanvasElement) => {
      // console.log('canvas', htmlEl.getBoundingClientRect());

      CanvasEffectsContainer.containerAsElement(htmlEl);

      htmlEl.style.opacity = '0';

      let width = canvas.width;
      let height = canvas.height;
      // console.group('width height');
      // console.log('canvas', canvas);
      // console.log('width', width);
      // console.log('height', height);
      // console.groupEnd();

      const ctx = canvas.getContext('2d');
      let idata: ImageData = ctx?.getImageData(
        0,
        0,
        width,
        height
      ) as ImageData;
      let datums: ImageData[] = [];

      if (idata) {
        for (let i = 0; i < 32; i++) {
          datums.push(ctx?.createImageData(width, height) as ImageData);
        }

        for (let f = 0; f < width; f++) {
          for (let k = 0; k < height; k++) {
            let n = 4 * (k * width + f);
            // let m = Math.floor(32 * Math.random());
            let m = Math.floor((32 * (Math.random() + (2 * f) / width)) / 3);
            for (let p = 0; p < 4; p++) {
              datums[m].data[n + p] = idata.data[n + p];
            }
          }
        }
      }

      datums.forEach((imageData, i) => {
        let cloned = canvas.cloneNode() as HTMLCanvasElement;

        // cloned.style.transition = 'all 1.5s ease-out'; // transform opacity
        cloned.style.transition = 'all 3s ease-out ' + i / 32 + 's';

        cloned.getContext('2d')?.putImageData(imageData as ImageData, 0, 0);
        document
          .getElementById('canvas-effects-container')
          ?.appendChild(cloned);
        setTimeout(() => {
          let angle = ((Math.random() - 0.5) * 2 * Math.PI) as number;
          let rotateAnge = 15 * (Math.random() - 0.5);
          cloned.style.transform =
            'rotate(' +
            rotateAnge +
            'deg) translate(' +
            60 * Math.cos(angle) +
            'px, ' +
            60 * Math.sin(angle) +
            'px) rotate(' +
            rotateAnge +
            'deg)';

          cloned.style.opacity = '0';
        }, 4);
      });
    });
  };
}
