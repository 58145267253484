import React, { useReducer } from 'react';
import AppContext from 'src/context/App/AppContext';

import { AppStateType } from 'src/types';
import { appReducer } from './AppReducer';

const initialState: AppStateType = {
  notification: 'test notification',
};

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  return (
    <AppContext.Provider value={{ state }}>{children}</AppContext.Provider>
  );
};

export default AppProvider;
